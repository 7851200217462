import { useRouter } from "next/router";
import en from "../locales/en";
import de from "../locales/de";

export default function HeaderSection() {
  const { locale } = useRouter();
  const t = locale === "en" ? en : de;

  return (
    <>
      <section className="flex items-center h-screen w-full font-medium text-center md:text-left text-6xl md:text-8xl lg:text-9xl">
        <div className="md:w-4/5 mx-auto">
          <h1 className="text-gold hover:text-goldHover font-bold">
            {t.title}
          </h1>
          <h1 className="text-blau hover:text-blauHover md:translate-x-14 font-medium">
            {t.subtitle}
          </h1>
          <h2 className="text-turkis hover:text-hellGrau text-5xl pt-32 md:pt-0 font-light hover:bg-turkis">
            {t.paragraph}
          </h2>
        </div>
      </section>
    </>
  );
}
