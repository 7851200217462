// now based on:
// https://github.com/vercel/next.js/blob/canary/examples/i18n-routing/components/locale-switcher.js

// official i18n-next
// https://github.com/isaachinman/next-i18next

import Link from "next/link";
import { useRouter } from "next/router";
import Container from "../components/Container";
import HeaderSection from "../components/headerSection";
import LocaleSwitcher from "../components/locale-switcher";

export default function IndexPage(props) {
  const router = useRouter();
  const { locale, locales, defaultLocale } = router;

  return (
    <>
      <Container>
        <HeaderSection />
        <div>
          {/*
    <div className="w-2/3 mx-auto bg-emerald-900">
      <div className="space-x-8">
        <h1 className="inline">Index page</h1>
        <p className="inline">Current locale: {locale}</p>
        <p className="inline">Default locale: {defaultLocale}</p>
        <p className="inline">Configured locales: {JSON.stringify(locales)}</p>
      </div>
      

      <LocaleSwitcher />
      
      <div className="space-x-8">
        <Link href="/gsp">
          <a>To getStaticProps page</a>
        </Link>

        <Link href="/gsp/first">
          <a>To dynamic getStaticProps page</a>
        </Link>

        <Link href="/gssp">
          <a>To getServerSideProps page</a>
        </Link>
      </div>

      
    </div>
    */}
        </div>
      </Container>
    </>
  );
}
